import React, { useState } from 'react'
import Layout from '../components/layout'
import { Container, Row, Col, Form, Button, FormGroup, Input } from 'reactstrap'
import banner from '../images/Landing-Page-banner.jpg'
import landingimg1 from '../images/landing-image1.png'
import landingimg2 from '../images/landing-image2.png'
import landingimg3 from '../images/landing-image3.png'
import landingimg4 from '../images/landing-image4.png'
import mainbg2 from '../images/main-bg-2.jpg'
import landingpagebg from '../images/Landing-Page-bg.jpg'
import { Link } from 'gatsby'
import { useFirebase } from 'gatsby-plugin-firebase'
const MeetTheTeam = () => {

    const [uid, setUid] = useState("")

    return (
        <Layout
            seo={{ title: "Australian Personal Financial Planners", description: 'Our focus at Solutions is to help individuals achieve their lifestyle goals.', keyword: '' }}
            banner={<FreeConsulTationBanner uid={uid}/>}
            navStayle="SIMPLE"
            setUid={(uid: string) => setUid(uid)}
        >


            <section style={{ margin: '0', padding: '50px 0' }}>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={10} style={{ textAlign: 'center' }}>
                            <h4   >We take the stress out of managing your finances, so you have the power to move forward in life.  For the past 10 years we have helped people in the mining industry take control of their finances, increase wealth and secure their future.</h4>
                            <h4   >If you are willing to invest just 20 minutes of your time to tell us your story and how we can help you, we are equally happy to invest our time in you and see if we can work together.</h4>
                            <a href="/1-on-1-phone-call/#head" className="btn btn-primary btn-lg"><span style={{ fontSize: 18 }}> Book Now</span></a>
                        </Col>
                    </Row>

                </Container>
            </section>
            <section style={{ padding: "0px 0 80px 0", }}>
                <Container>
                    <h3 className="header-font" style={{ fontSize: '1.5em', textAlign: 'center' }}>3 Easy Steps to Building Wealth and Securing your Retirement</h3>
                    <Row>
                        <Col md={6}>
                            <img src={landingimg1} alt=""></img>
                        </Col>
                        <Col md={6}>
                            <Row className="olli">
                                <Col md={2}>
                                    <span className="ullino">1</span>
                                </Col>
                                <Col  md={10}>One call will allow us time to get to know you and understand what makes you happy with the view of building a long-term relationship.</Col>
                            </Row>
                            <Row className="olli">
                                <Col md={2}>
                                <span className="ullino">2</span>
                                </Col>
                                <Col md={10}>We build a plan to help you work towards your desired lifestyle and financial goals.</Col>
                            </Row>
                            <Row className="olli">
                                <Col md={2}>
                                <span className="ullino">3</span>
                                </Col>
                                <Col md={10}>We partner with you and guide you along the way to achieving your long-term plans.  Don't worry -if circumstances change we will help you adjust.</Col>
                            </Row>

                        </Col>

                    </Row>
                    <Row>
                        <Col md={12} style={{ margin: '30px 0', textAlign: "center" }}>
                            <h5>Schedule a 20-minute, no obligation call with us. </h5>
                            <p>Over the last 10 years we have been helping workers in the mining industry to take control of their finances resulting in a desired lifestyle. </p>
                        </Col>
                    </Row>
                    <Row style={{ margin: '50px 0' }}>
                        <Col md={6} style={{ marginTop: '6%' }}>
                            <h3 className="header-font" style={{ fontSize: '1.7em' }} >Savings and Retirement Strategies Made For You</h3>
                            <h5 className="">We have a top-down approach meaning we will discuss with you your ideal lifestyle and then work backwards to ensure your current financial position will support your desired future.</h5>
                            <h5 className="">Join the hundreds of people/ couples/ families who now have less stress and more financial freedom. </h5>
                        </Col>
                        <Col md={6}>
                            <img src={landingimg2} alt=""></img>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ padding: "100px 0 50px", background: `url(${landingpagebg})`, backgroundPosition: 'bottom center' }}>
                <Container>
                    <Row>
                        <Col md={6}>
                        </Col>
                        <Col md={6} style={{ background: '#15abe3b0' }}>

                            <p className="" style={{ color: '#fff', fontWeight: 500 }}>In just one meeting with our team, you will learn strategies to:</p>
                            <ul>
                                <li className="" style={{ color: '#fff' }}>Protect your family, income and assets. </li>
                                <li className="" style={{ color: '#fff' }}>Get a better understanding of your superannuation.</li>
                                <li className="" style={{ color: '#fff' }}>Take control of your cash flow and debts.</li>
                                <li className="" style={{ color: '#fff' }}>Have a plan to secure your retirement.</li>

                            </ul>
                            <p className="" style={{ color: '#fff', fontWeight: 500 }}>We have helped hundreds of people across Australia by using tailored strategies and we can do the same for you.</p>

                            <a href="/1-on-1-phone-call/#head" className="btn btn-primary btn-lg"><span style={{ fontSize: 18 }}> Book your appointment now</span></a>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ padding: "100px 0 80px 0", background: `url(${mainbg2})`, backgroundSize: 'cover', backgroundPosition: 'top center' }}>
                <Container>
                    <Row>
                        <Col md={6} style={{ marginTop: '5%' }}>
                            <h3 className="header-font">Our clients couldn't be happier!</h3>
                            <p className="" style={{ color: '#000', fontWeight: 400 }}> <i className="fas fa-quote-left pr-2"></i>I am now able to plan for future retirement with a lot more confidence, as I have confidence in my consultant's ability to do the best for me.  </p>
                            <p className="" style={{ color: '#000', fontWeight: 400 }}>I have not only seen an improvement in my super return but I have found that I have a better understanding of my week to week spend and how I should manage my own savings. <i className="fas fa-quote-right pr-2"></i></p>

                            <p className="" style={{ color: 'rgb(1, 107, 146)', fontWeight: 400, fontSize: '1.3em' }}>-Kev & Kris Rahley</p>
                        </Col>
                        <Col md={6}>
                            <img src={landingimg3} alt=""></img>

                        </Col>

                    </Row>
                    <Row style={{ margin: '50px 0' }}>
                        <Col md={6}  >
                            <img src={landingimg4} alt=""></img>
                        </Col>
                        <Col md={6}>

                            <h3 className="header-font">Book Your Free  Consultation Today!</h3>
                            <ul>
                                <li className="">Are you worried about your spending habits?</li>
                                <li className="">Are you worried about your retirement?</li>
                                <li className="">Are you worried about being able to provide for your family if you cannot work?  </li>
                                <li className="">Do you need someone to help you gain control of your finances?  </li>
                            </ul>
                            <p className="" style={{ color: '#000', fontWeight: 400 }}>If YES, book your consultation with us!  </p>
                            <p className="" style={{ color: '#000', fontWeight: 400 }}>Solutions Consultants can help!  We helped hundreds of people take control of their finances and start investing in their future. Our team will build a tailored financial plan for you.</p>

                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ padding: '50px 0' }}>
                <Container>
                    <Row className="justify-content-md-center">
                        <Col md={8} style={{ textAlign: 'center' }}>
                            <h3 className="header-font">Let’s have a chat about how we can help you. </h3>
                            <Link className="btn btn-info btn-lg btn-warning" style={{ fontSize: 20 }} to="/1-on-1-phone-call#head">Schedule an Appointment with Us</Link>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

const FreeConsulTationBanner = (props: {uid: string} ) => {
    const fb: any = null
    const [firebase, setFirebase] = useState(fb)
    const [name, setName] = useState()
    const [email, setEmail] = useState()
    const [phone, setPhone] = useState()
    const [location, setLocation] = useState()
    const [message, setMessage] = useState()
    const [timeToCall, setTimeToCall] = useState() 
    const [submited, setSubmited] = useState(false)
    useFirebase((fb: any) => {
        setFirebase(fb);
    }, [])

    const submitHendler = (event: any) => {
        event.preventDefault()

        const dataPost = {
            name, email, phone, location, timeToCall,  message, page: '1-on-1-phone-call'
        }
        firebase.database().ref(`solutionsconsultants/websiteLeads/${props.uid}`).set(dataPost).then((d: any) => {
            console.log("Sudah Lewat Sini", d)
            setSubmited(true)
        }).catch(
            (err: any) => {
                console.log(err)
            }
        )
        console.log(dataPost)
    }
    console.log("submited", submited)
    let formDisplay = (
        <div style={{ padding: 30, background: '#ffffffe8', marginTop: 150 }} >
            <h3>Let's Have A Chat - Book Your Free Call Now</h3>


            <Form>
                <div className="form-row" style={{ marginBottom: 15 }}>
                    <div className="col-md-6" >
                        <Input placeholder="Name" type="text" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setName(evt.target.value)
                        }}></Input>
                    </div>
                    <div className="col-md-6">
                        <Input placeholder="Email" type="email" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setEmail(evt.target.value)
                        }}></Input>
                    </div>
                </div>
                <div className="form-row" style={{ marginBottom: 15 }}>
                    <div className="col-md-6">
                        <Input placeholder="Phone" type="text" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setPhone(evt.target.value)
                        }}></Input>
                    </div>
                    <div className="col-md-6" >
                        <Input placeholder="Location" type="text" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setLocation(evt.target.value)
                        }}></Input>
                    </div>
                </div>
                <div className="form-row" style={{ marginBottom: 15 }}>
                    <div className="col-md-6">
                        <Input id="exampleFormControlSelect1" placeholder="Best time to call" type="select" style={{ background: '#fff', fontSize: 16 }} onChange={(evt: any) => { 
                            setTimeToCall(evt.target.value)
                        }}>
                            <option value="">Best time to call</option>
                            <option>Morning</option>
                            <option>Afternoon</option>
                            <option>Evening</option>
                            <option>Any Time</option> 
                        </Input>

                    </div>
                    <div className="col-md-6" >

                    </div>
                </div>
                <FormGroup>
                    <label htmlFor="exampleFormControlTextarea1"> Message </label>
                    <Input
                        id="exampleFormControlTextarea1"
                        rows="3"
                        type="textarea"
                        placeholder=" Is there anything specific you would like us to know? "
                        className='form-control' onChange={(evt: any) => {
                            //console.log(evt.target.value)
                            setMessage(evt.target.value)
                        }}
                        style={{ border: "1px solid #e3e3e3", borderRadius: "10px", background: '#fff', fontSize: 16 }}
                    ></Input>
                </FormGroup>
                <div style={{ textAlign: "center" }}>
                    <Button className="btn-round" color="primary" type="button" onClick={(evt) => submitHendler(evt)}>
                        <i className="now-ui-icons ui-1_send"></i> Submit </Button>
                </div>

            </Form>
        </div>
    )
    if (submited) {
        formDisplay = <div style={{ minHeight: 250, background: 'rgba(255, 255, 255, .9)', marginTop: 150, padding: 30, color: '#000', fontWeight: 500 }}>
            <p style={{ color: '#000', fontWeight: 500 }}>Hi {name}, </p>
            <p style={{ color: '#000', fontWeight: 500 }}>Thank you for contacting us at Solutions Consultants. <br />
Our team will be in touch shortly to chat with you, to see how we can help, to work with you and make your life easier.</p>
            <p style={{ fontWeight: 500 }}>
                Phone <a style={{ fontWeight: 500 }} href="tel: +61 3 9826 6655">+61 3 9826 6655</a> <br />
                <a style={{ fontWeight: 500 }} href="mailto:info@solutionsconsultants.com.au" target="_blank">info@solutionsconsultants.com.au</a><br />
                <a style={{ fontWeight: 500 }} href="https://solutionsconsultants.com.au" target="_blank">https://solutionsconsultants.com.au</a><br />
            </p>
        </div>
    }
    return (
        <>
            <div id="head" style={{ backgroundImage: `url(${banner})`, backgroundSize: 'cover', backgroundPosition: 'bottom center', minHeight: 750 }}>
                <Container>
                    <Row style={{ paddingBottom: 50 }}>
                        <Col md={6}>
                            <div style={{ marginTop: '200px' }}>
                                <span className="landing-page-head" style={{ textTransform: 'uppercase', fontSize: 36 }}>CAN ONE MEETING help</span><br />
                                <span className="landing-page-head" style={{ textTransform: 'uppercase', fontSize: 36 }}>with your financial direction? </span><br />
                                <span style={{ background: '#15abe3', textTransform: 'uppercase', fontSize: 36 }} className="landing-page-head">YES IT CAN!</span>
                            </div>
                        </Col>
                        <Col md={6}>

                            {formDisplay}
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default MeetTheTeam